<script setup>
import Close from 'assets/icons/close.svg';
import Back from 'assets/icons/back.svg';

const props = defineProps({
    data: {
        type: Object,
        default: () => {
        }
    },
    cb: {
        type: Function,
        default: () => {
        }
    },
    isSubnav: {
        type: Boolean,
        default: false
    }
});

// Set dynamic component in sidebar, depending on login status
const userAccount = resolveComponent('LazyUserAccountOverview');
const userAccountPersonalData = resolveComponent('LazyUserAccountPersonalDataForm');
const userAccountPreferences = resolveComponent('LazyUserAccountPreferencesForm');
const userAccountDeletion = resolveComponent('LazyUserAccountDeletionForm');
const UserAccountFeedbackForm = resolveComponent('LazyUserAccountFeedbackForm');
const UserAccountSupportNote = resolveComponent('LazyUserAccountSupportNote');
const centerDetailSideBar = resolveComponent('LazyCenterDetailSideBar');
const centerSelectorSideBar = resolveComponent('LazyCenterSelectorSideBar');

const components = {
    preferences: userAccountPreferences,
    personal: userAccountPersonalData,
    deleteAccount: userAccountDeletion,
    feedbackForm: UserAccountFeedbackForm,
    supportNote: UserAccountSupportNote,
    account: userAccount,
    centerDetail: centerDetailSideBar,
    centerSelector: centerSelectorSideBar
}

const sideBarClass = computed(() => {
    return {
        'close-sub-sidebar': props.isSubnav,
        'close-sidebar': !props.isSubnav
    }
})
</script>

<template>
    <Transition name="fade">
        <div v-if="data?.open" class="sidebar-close-layer" @click="cb({open: false})" />
    </Transition>

    <Transition name="fade-to-right">
        <aside v-if="data?.open" class="sidebar">
            <div :class="sideBarClass" @click="cb({open: false})">
                <Back v-if="isSubnav" />
                <Close v-else />
            </div>
            <div class="sidebar-content">
                <component :is="components[data?.content]" />
            </div>
        </aside>
    </Transition>
</template>

<style lang="scss">
.sidebar-close-layer {
    position: fixed;
    z-index: z('side-bar-layer');
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    background: $sidebar-close-layer-bg;
}

.sidebar {
    position: fixed;
    z-index: z('side-bar-container');
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    // height: 100vh;
    max-width: 500px;
    transition: max-width 600ms ease, transform 0.6s ease-out;
}

.sidebar-content {
    @include scrollbar;

    background: $sidebar-content-bg;
    box-shadow: 20px 20px 40px $sidebar-content-shadow;
    height: 100%;
}

.close-sub-sidebar,
.close-sidebar {
    position: absolute;
    z-index: z('close-side-bar');
    top: 14px;
    right: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $close-sidebar-icon-bg;
    box-shadow: 0 0 10px $close-sidebar-icon-shadow1, 6px 6px 12px 3px $close-sidebar-icon-shadow2;
    border-radius: 12px;

    svg {
        fill: $close-sidebar-icon-fill;
        width: 14px;
        height: 14px;
    }
}

.close-sub-sidebar {
    right: auto;
    left: 14px;

    svg {
        width: 14px;
        height: 18px;
    }
}
</style>
